<template>
  <div>
    <article class="message is-dark">
      <div class="message-body">
        <nav class="pagination is-centered is-rounded" role="navigation" aria-label="pagination">
          <a class="pagination-previous" @click.prevent="changePage(1)" :disabled="meta.current_page==1">Inicio</a>
          <a class="pagination-previous" @click.prevent="changePage(meta.current_page-1)" :disabled="links.prev==null">Anterior</a>
          <a class="pagination-next" @click.prevent="changePage(meta.current_page+1)" :disabled="links.next==null">Siguiente</a>
          <a class="pagination-next" @click.prevent="changePage(meta.last_page)" :disabled="links.last==null || meta.current_page==meta.last_page">Última</a>
          <ul class="pagination-list">
            <li v-for="(i, index) in pages" :key="index">
              <a v-bind:class="meta.current_page==i ? 'pagination-link is-current' : 'pagination-link'" @click.prevent="changePage(i)">{{i}}</a>
            </li>
          </ul>
        </nav>
      </div>
    </article>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['links','meta'],
  data () {
    return {
    }
  },
  methods:{
    changePage(page) {
      this.meta.current_page = page;
      this.$emit('paginate');
      this.pages=[];
    }
  },
  created :function(){
  },
  computed: {
    pages() {
      let pages=[];
      let from = this.meta.current_page - Math.floor(5 / 2);
      if (from < 1) {
        from = 1;
      }
      let to = from + 5 - 1;
      if (to > this.meta.last_page) {
        to = this.meta.last_page;
      }
      while (from <= to) {
        pages.push(from);
        from++;
      }
      return pages;
    }
  }
}
</script>

<style>
.pagination-link.is-current {
  background-color: #490862!important;
  border-color:#490862!important;
  color:#fff;
}
.message{
  color:#fff!important;
}
</style>
