import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import Announcement from '../views/Announcement.vue'
import Cast from '../views/Cast.vue'
import Auditions from '../views/Auditions.vue'
import Error404 from '../views/Error404.vue'
import Programs from '../views/Programs.vue'
import Videos from '../views/Videos.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/avisos',
    name: 'Announcement',
    component: Announcement
  },
  {
    path: '/convocatorias',
    name: 'Cast',
    component: Cast
  },
  {
    path: '/anuncios-y-ayudiciones',
    name: 'Auditions',
    component: Auditions
  },
  {
    path: '/programas-sociales',
    name: 'Programs',
    component: Programs
  },
	{
    path: '/videos',
    name: 'Videos',
    component: Videos
  },
  { path: "*", component: Error404}

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
