<template>
  <div>
    <section class="hero is-danger-bg">
      <div class="hero-body">
        <p class="title">
          Contacto
        </p>
        <p class="subtitle">
          Dejanos un mensaje!
        </p>
      </div>
    </section>

    <section class="section">
     <div class="container">

       <div class="columns mt-1">

         <div class="column is-7">
           <form @submit.prevent="submit">
             <div class="notification is-primary" v-if="success">
               <button class="delete" @click="success=false"></button>
               Pronto nos pondremos en contacto contigo.
             </div>
             <div class="notification is-danger" v-if="errors">
               <button class="delete" @click="errors=false"></button>
               Error no podemos procesar tu petición en estos momentos intenta de nuevo.
             </div>

             <div class="field is-horizontal">
               <div class="field-label is-normal">
                 <label class="label">Nombre</label>
               </div>
               <div class="field-body">
                 <div class="field">
                   <div class="control">
                     <input class="input" type="text" v-model="name_contact" name="name_contact" placeholder="">
                   </div>
                 </div>
               </div>
             </div>

             <div class="field is-horizontal">
               <div class="field-label is-normal">
                 <label class="label">Email</label>
               </div>
               <div class="field-body">
                 <div class="field">
                   <div class="control">
                     <input class="input" type="email" v-model="email_contact" name="email_contact" placeholder="">
                   </div>
                 </div>
               </div>
             </div>

             <div class="field is-horizontal">
               <div class="field-label is-normal">
                 <label class="label">Mensaje</label>
               </div>
               <div class="field-body">
                 <div class="field">
                   <div class="control">
                     <textarea class="textarea" v-model="message_contact" name="message_contact"></textarea>
                   </div>
                 </div>
               </div>
             </div>

             <div class="field is-horizontal">
               <div class="field-label is-normal">
                 <label class="label">Captcha</label>
               </div>
               <div class="field-body">
                 <div class="field">
                   <vue-recaptcha ref="recaptcha" sitekey="6LejPFgaAAAAADacmZ1E57eaNc911ywRxe9Lhnhq" @verify="onVerify" @expired="onExpired"></vue-recaptcha>
                 </div>
               </div>
             </div>

             <div class="field is-horizontal">
               <div class="field-label">
                 <!-- Left empty for spacing -->
               </div>
               <div class="field-body">
                 <div class="field">
                   <div class="control">
                     <button :disabled="status==='submitting'" class="button is-danger-btn">Enviar</button>

                   </div>
                 </div>
               </div>
             </div>

           </form>

         </div>

         <div class="column is-5">
           <iframe class="map-google" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14882.845170995924!2d-86.8295708!3d21.1638848!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeb33b59c4dbe4cf6!2sSindicato+de+la+CROC%2C+Cancun!5e0!3m2!1ses!2smx!4v1539417058179" frameborder="0" style="border:0" allowfullscreen></iframe>
         </div>


       </div>
     </div>
   </section>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios';

export default {
  name: 'Contact',
  data () {
    return {
       status: "",
       email_contact:"",
       message_contact:"",
       name_contact:"",
       success:false,
       errors:false,
    }
  },
  components: {
    VueRecaptcha,
  },
  methods:{
    submit: function () {
      //this.$refs.invisibleRecaptcha.execute();
    },
    onVerify: function (recaptchaToken) {
      const self = this;
      this.$refs.recaptcha.reset();
      axios.post('https://api.plataformasocialmedia.pw/api/v1/contact?api_token=kxt11MMXZ8x5DUjdxzdDip14o5tuO9dePLujJRuZfXs3LdCc80', {
        type_web:'musicoscroc',
        email_contact: self.email_contact,
        name_contact:self.name_contact,
        message_contact:self.message_contact,
        recaptchaToken: recaptchaToken,
      }).then(() => {
        self.success = true;
        self.status = '';
      }).catch(() => {
       self.errors = true;
       self.status = '';
     });

    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
    }
  },
}
</script>

<style>
.map-google{
  height: 400px;
  width: 500px;
}
.is-danger-btn{
  background-color:#490862!important;
  color:#fff!important;
}
</style>
