<template>
  <div>
    <section class="hero is-danger-bg">
      <div class="hero-body">
        <p class="title">
          Programas Sociales

        </p>
        <p class="subtitle">
          Sindicalismo Social del Siglo XX
        </p>
      </div>
    </section>


        <section class="section">
          <div class="container">
            <div class="columns">
              <div class="column">

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=1">
                    <p>Acuerdo Nacional contra el cambio climático</p>
                  </div>
                  <div class="message-body" v-show="isOpen==1">
                    <h4 class="is-pulled-left has-text-weight-bold">JORNADAS DE LIMPIEZA Y REFORESTACIÓN</h4><br>
                    <p class="has-text-justified">
                      De acuerdo al Acuerdo Nacional contra el Cambio Climático que el Senador Isaías González Cuevas firmó en el Alcázar del Catillo de Chapultepec, realizamos acciones en conjunto con la sociedad civil para la defensa del ecosistema, como es el caso de la adopción de playas, el manejo y reciclaje de la basura, las jornadas de reforestación y la limpieza de parques, ríos y espacios públicos.
                    </p>
                    <img src="../assets/programas/ps-reforesta.jpg" alt="Acuerdo Nacional contra el cambio climático"/><br>
                    <h4 class="is-pulled-left has-text-weight-bold"> MOVIMIENTO NACIONAL POR EL TURISMO VERDE</h4><br>
                    <p class="has-text-justified">
    Con el objetivo de mitigar los impactos del cambio climático, impulsamos el Movimiento Nacional por el Turismo Verde, mediante el cual concientizamos a los trabajadores y sus familias sobre la importancia de la conservación de las bellezas naturales y el aumento del turismo en nuestro país. En coordinación con la Secretaría de Turismo y las recomendaciones de la Organización Mundial del Turismo, implementamos un decálogo que tiene como puntos principales: revalorizar, reducir, reutilizar y reciclar. Para reforzar estas acciones, se creó la Comisión Turística Verde (COMTUVE) con la que el trabajador se compromete a realizar su trabajo cuidando su entorno natural, esto implica una cultura del reciclaje y el uso racional de los recursos naturales.
                    </p>
                    <img src="../assets/programas/ps-verde.jpg" alt="MOVIMIENTO NACIONAL POR EL TURISMO VERDE"/><br>

                    <h4 class="is-pulled-left has-text-weight-bold"> ACCIONES CONTRA EL CALENTAMIENTO GLOBAL</h4><br>
                    <p class="has-text-justified">
    El calentamiento global es un fenómeno que afecta gravemente la vida planetaria, ocasionado principalmente por la actividad humana. Para crear conciencia en la comunidad acerca de este problema e informar sobre las diversas formas de ayudar a resarcirlo desde prácticas cotidianas, realizamos foros, conferencias y rally´s en escuelas y centros laborales.
                    </p>
                    <img src="../assets/programas/ps-calentamiento.jpg" alt="ACCIONES CONTRA EL CALENTAMIENTO GLOBAL"/><br>

                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=2">
                    <p>Afiliación Voluntaria</p>
                  </div>
                  <div class="message-body" v-show="isOpen==2">
                    <p class="has-text-justified">Fomentamos una conciencia colectiva para construir y reforzar la identidad social de los trabajadores, con la intención de gestionar moderadamente el sindicalismo con diálogo y concertación, buscando la superación económica, social y cultural de los trabajadores.</p>
                    <img src="../assets/programas/ps-afiliacion.jpg" alt="Afiliación Voluntaria"/><br>

                  </div>

                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=3">
                    <p class="has-text-justified">Combate a la violencia intrafamiliar</p>
                  </div>
                  <div class="message-body" v-show="isOpen==3">
                    <p class="has-text-justified">Gracias a nuestra alianza establecida con instituciones y organismos no gubernamentales, los Asesores Sociales son capacitados y certificados como Orientadores Familiares, con el objetivo de promover la convivencia familiar positiva para que puedan resolver sus conflictos en armonía, poniendo en práctica los valores aprendidos. Para lograrlo, impartimos cursos, pláticas de sensibilización, representaciones de teatro y sesiones de video-debate con cortometrajes.</p>
                    <p class="has-text-justified">Nuestra misión es brindar las herramientas necesarias a los padres de familia para que desempeñen mejor su rol como formadores de sus hijos y fortalecer la relación de pareja, con el propósito de disminuir el índice de divorcios.</p>
                    <img src="../assets/programas/ps-violencia.jpg" alt="Combate a la violencia intrafamiliar"/><br>

                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=4">
                    <p class="has-text-justified">Contra la trata de personas</p>
                  </div>
                  <div class="message-body" v-show="isOpen==4">
                    <p class="has-text-justified">
    Con este programa brindamos a los trabajadores, sus familias y comunidades herramientas para la efectiva prevención y combate del delito de la trata de personas, a través de la generación de procesos de sensibilización y concientización
                    </p>
                    <p class="has-text-justified">
    Para alcanzar nuestro objetivo la CROC cuenta con una red nacional integrada por más de 2000 Facilitadores quienes se han capacitado en el tema, gracias a la colaboración con la Comisión Nacional de Derechos Humanos, la Fiscalía Especial para los Delitos de Violencia contra las Mujeres y Trata de Personas (FEVIMTRA) de la Procuraduría General de la República, Microsoft México, Genero y Desarrollo, GENDES A.C. y Fundación Infantia A.C.
                    </p>
                    <p class="has-text-justified">
    Actualmente estamos llevando a las empresas, colonias y escuelas información valiosa para que juntos aprendamos a identificar el delito, prevenirlo y proporcionar herramientas para proteger a nuestras familias.
                    </p>
                    <p class="has-text-justified">
    Para la intensa y permanente labor que impulsamos en las 32 entidades de la república mexicana contamos con aliados estratégicos como la Organización Internacional del Trabajo, la Secretaria de Trabajo y Previsión Social, el Instituto Nacional de las Mujeres y el Sistema Nacional para el Desarrollo Integral de la Familia, entre otros.
                    </p>
                    <img src="../assets/programas/ps-trata.jpg" alt="Contra la trata de personas"/><br>

                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=5">
                    <p class="has-text-justified">Cultura de la Salud</p>
                  </div>
                  <div class="message-body" v-show="isOpen==5">
                    <p class="has-text-justified">
    Con el objetivo de crear una cultura de la salud entre los agremiados y sus familias, firmamos un convenio de colaboración con el IMSS, mediante el cual llevamos a cabo el programa PREVENIMSS, el cual incluye las siguientes acciones:
                    </p>
                    <p class="has-text-justified">
    Acreditación de delegados en todo el país.<br>
    Promoción de la credencialización.<br>
    Proporción de asesoría oportuna y gratuita en temas como incapacidades, pensiones, afiliación, vigencia de derechos y diversos trámites administrativos que se deben realizar ante el IMSS.<br>
    Realización de Jornadas de salud en coordinación con especialistas para llevar a cabo estudios de detección de cáncer en matriz, próstata y mama, detección oportuna de diabetes y obesidad, entre otros.<br>
                    </p>
                    <img src="../assets/programas/ps-salud.jpg" alt="Cultura de la Salud"/><br>

                    <h4 class="is-pulled-left has-text-weight-bold"> SEGURIDAD SOCIAL</h4><br>

                    <p class="has-text-justified">
    En conjunto con el Instituto Mexicano del Seguro Social, otorgamos a los trabajadores y a sus familias la protección suficiente y oportuna ante contingencias tales como enfermedades, vejez, invalidez o defunción.
                    </p>
                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=6">
                    <p class="has-text-justified">Cultura en la Prevención de Adicciones</p>
                  </div>
                  <div class="message-body" v-show="isOpen==6">
                    <p class="has-text-justified">
    Realizamos foros nacionales sobre prevención de las adicciones e invitamos a diferentes organizaciones no gubernamentales, del sector público y del privado, para desarrollar propuestas y acciones de trabajo en materia de prevención, atención, rehabilitación y reinserción social.
                    </p>
                    <p class="has-text-justified">
    Los Asesores Sociales son profesionalizados con el apoyo de la Organización Internacional del Trabajo, la Organización Panamericana de la Salud, la Secretaría de Salud, el Consejo Nacional contra las Adicciones, el Instituto Nacional de Psiquiatría y Centros de Integración Juvenil, entre otras instituciones.
                    </p>
                    <img src="../assets/programas/ps-adicciones.jpg" alt="Cultura en la Prevención de Adicciones"/><br>

                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=7">
                    <p class="has-text-justified">Deporte, Cultura y Recreación</p>
                  </div>
                  <div class="message-body" v-show="isOpen==7">
                    <p class="has-text-justified">
    Llevamos a cabo actividades deportivas, culturales y de recreación en el lugar donde viven y conviven las familias de los trabajadores: Lucha libre, box, fútbol, físico constructivismo, concursos de canto «Voces del Alma», de baile «Bailando por la CROC», concurso de modelaje «Señorita Ecomundo», así como obras de teatro y conciertos de música.
                    </p>
                    <img src="../assets/programas/ps-deporte.jpg" alt="Deporte, Cultura y Recreación"/><br>

                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=8">
                    <p class="has-text-justified">Educación</p>
                  </div>
                  <div class="message-body" v-show="isOpen==8">

                    <p class="has-text-justified">
    Con el apoyo del Instituto Nacional para la Educación de los Jóvenes y Adultos (INEA), hemos avanzado en la construcción de plazas comunitarias de educación abierta y escolarizada en todo el país, en las cuales cada vez más compañeros obtienen su certificado de primaria, secundaria y bachillerato, así como de computación e inglés. Además, contamos con centros de educación nacional y con el Instituto Universitario Tecnológico de los Trabajadores en la Ciudad de Monterrey, Nuevo León.
                    </p>
                    <p class="has-text-justified">
    La meta es que los croquistas y sus familias concluyan el bachillerato con la opción de continuar sus estudios superiores, a través de las firmas de colaboración con distintas universidades de prestigio.
                    </p>
                    <img src="../assets/programas/ps-educacion.jpg" alt="Educación"/><br>

                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=9">
                    <p class="has-text-justified">Empleo y Productividad</p>
                  </div>
                  <div class="message-body" v-show="isOpen==9">
                    <p class="has-text-justified">
    Contamos con el apoyo del Centro Nacional de Desarrollo de Empleo y Productividad, con el cual trabajamos en la competitividad de las empresas a través de la capacitación y la profesionalización de los trabajadores. Con la Alianza para el Empleo y las Cámaras Empresariales, hemos acordado e impulsado la apertura de nuevas empresas y la reactivación de cadenas productivas, acercando por medio de ferias a productores y microempresarios. Esta alianza prioriza el apoyo de la formación de los trabajadores con programas de capacitación que les da el acceso a mayores oportunidades laborales.
                    </p>
                    <img src="../assets/programas/ps-empleo.jpg" alt="Empleo y Productividad"/><br>

                    <h4 class="is-pulled-left has-text-weight-bold"> CONOCER</h4><br>
                    <p class="has-text-justified">
    Al ser una entidad evaluadora y certificadora de competencias laborales, brindamos a nuestros agremiados una excelente capacitación con el aval de la Secretaría de Educación Pública (SEP) y los estándares de la Comisión Nacional de Normalización y Competencia Laboral (CONOCER).
                    </p>
                    <h4 class="is-pulled-left has-text-weight-bold">CURSOS: </h4><br>
                    <p class="has-text-justified">
                      Preparación de alimentos.<br>
                      Preparación de Bebidas.<br>
                      Atención a comensales.<br>
                      Limpieza de habitaciones de alojamiento temporal.<br>
                    </p>
                    <h4 class="is-pulled-left has-text-weight-bold"> CAPACITACIÓN</h4><br>
                    <p class="has-text-justified">
    Asimismo, contamos con la aplicación de cursos de capacitación para que los trabajadores del sector turístico y gastronómico puedan enfrentarse al mundo competitivo.
                    </p>
                    <p class="has-text-justified">
    Introducción al Sindicalismo.<br>
    Oratoria.<br>
    Asesores Vecinales<br>
    Cultura Turística.
                    </p>
                    <h4 class="is-pulled-left has-text-weight-bold">FORMACIÓN DE CUADROS SINDICALES </h4><br>

                    <p class="has-text-justified">
    El incremento de la afiliación sindical, la presencia en el centro de trabajo, la capacidad estratégica y las negociaciones en sus diversos niveles, dependen en gran medida de la disposición de cuadros sindicales altamente capacitados, por tal motivo, nuestro Líder Nacional, Senador Isaías González Cuevas, fundó la Escuela Nacional de Formación de Cuadros Sindicales, instancia que brinda a los dirigentes croquistas una serie de seminarios especializados para enfrentar los retos del mercado de trabajo y lograr el mejoramiento tanto laboral como social de los agremiados a esta central.
                    </p>
                    <p class="has-text-justified">
    Participación democrática.<br>
    Formación de cuadros sindicales.<br>
    Liderazgo
                    </p>
                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=10">
                    <p class="has-text-justified">Prevención al delito</p>
                  </div>
                  <div class="message-body" v-show="isOpen==10">

                    <p class="has-text-justified">
    A través de nuestro convenio de colaboración con la Secretaría de Seguridad Pública, impartimos cursos de prevención del delito cibernético y la victimización en la comunidad escolar y familiar, a fin de abrir espacios de capacitación abiertos para disuadir los actos de violencia y crear multiplicadores de la información. Para la CROC, es importante crear una cultura cibernética, con el propósito de orientar a los niños y jóvenes sobre la manera en la que pueden prevenir posibles problemas relacionados con el internet, asimismo, fomentar valores de respeto y armonía para evitar los casos de ciberbullying entre los estudiantes.
                    </p>
                    <img src="../assets/programas/ps-delito.jpg" alt="Prevención al delito"/><br>

                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=11">
                    <p class="has-text-justified">Turismo Social</p>
                  </div>
                  <div class="message-body" v-show="isOpen==11">

                    <p class="has-text-justified">
    Ofrecemos a las familias croquistas la oportunidad de conocer los destinos turísticos más importantes de México, con el propósito de procurar el derecho humano al esparcimiento y la recreación, así como fomentar la unión familiar y la cultura que envuelve nuestro bello país.
                    </p>
                    <img src="../assets/programas/ps-turismo.jpg" alt="Turismo Social"/><br>

                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=12">
                    <p class="has-text-justified">Vivienda para todos</p>
                  </div>
                  <div class="message-body" v-show="isOpen==12">
                    <p class="has-text-justified">
                      Los Asesores de Vivienda de la CROC tienen como misión visitar a las empresas afiliadas al sindicato para realizar encuestas que les permitan saber cuántos trabajadores no cuentan con una vivienda propia y apoyarlos en la obtención de una.
                      Realizamos ferias de vivienda en donde invitamos a las empresas constructoras a ofertar sus productos en los centros de trabajo, logrando con ello que conozcan las verdaderas necesidades de los trabajadores.
                    </p>
                    <img src="../assets/programas/ps-vivienda.jpg" alt="Vivienda para todos"/><br>

                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=13">
                    <p class="has-text-justified">Tecnologia Domestica</p>
                  </div>
                  <div class="message-body" v-show="isOpen==13">
                    <p class="has-text-justified">
    Gracias a las tecnologías domésticas,las familias aprenden a realizar sus propios productos de uso doméstico a un costo menor que los artículos comerciales. Esto les permite tener un mayor ahorro en sus hogares y la alternativa de autoemplearse.
                    </p>
                    <img src="../assets/programas/ps-tecnologiado.jpg" alt="Tecnologia Domestica"/><br>

                  </div>
                </article>

                <article class="message">
                  <div class="message-header" v-on:click="isOpen=14">
                    <p class="has-text-justified">Sabados Familiares</p>
                  </div>
                  <div class="message-body" v-show="isOpen==14">
                    <p class="has-text-justified">
                      Buscamos espacios en distintos puntos del municipio de Benito Juárez para promover el esparcimiento de las familias.
                    </p>
                    <img src="../assets/programas/ps-sabadosfa.jpg" alt="Sabados Familiares"/><br>

                  </div>
                </article>

              </div>
            </div>
          </div>
        </section>
  </div>
</template>

<script>

export default {
  name: 'Programs',
  data () {
    return {
      isOpen:0,
    }
  },
}
</script>
