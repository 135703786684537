<template>
  <div>
    <section class="hero is-danger-bg">
      <div class="hero-body">
        <p class="title">
          Avisos
        </p>
        <p class="subtitle">
          Mantente informado!
        </p>
      </div>
    </section>

    <div class="container mt-5 mb-5">
      <div class="columns has-text-centered" v-for="(announcement, index) in chunkedAnnouncement" :key="index">

        <div class="colum is-3" v-for="(a, i) in announcement" :key="i">
          <img :src="'https://api.plataformasocialmedia.pw/storage/'+a.cover"/>
          <h1> {{a.title}} </h1>
          <a target="_blank" :href="'https://api.plataformasocialmedia.pw/storage/'+a.pdf" class="button is-danger-btn">Leer más</a>
        </div>

      </div>
      <pagination v-if="links!=null" :links="links" :meta="meta" @paginate="init()"></pagination>

    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import axios from 'axios';
import {chunk} from 'lodash';

export default {
  name: 'Announcement',
  data () {
    return {
      announcement:[],
      links:[],
      meta:{'current_page' : 1},
      tota:0,
    }
  },
  components: {
    Pagination,
  },
  computed: {
    chunkedAnnouncement () {
      return chunk(this.announcement,3)
    }
  },
  methods:{
    init: function(){
      var self = this;
      axios.get('https://api.plataformasocialmedia.pw/api/v1/announcement?page='+this.meta.current_page+'&api_token=kxt11MMXZ8x5DUjdxzdDip14o5tuO9dePLujJRuZfXs3LdCc80')
      .then(function (response) {
        self.announcement=response.data.data;
        self.links=response.data.links;
        self.total=response.data.meta.total;
        self.meta=response.data.meta;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
  },
  created :function(){
    this.init();
  }
}
</script>


<style>

</style>
