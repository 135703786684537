<template>
  <div>
    <div class="has-background-black has-text-left mini-nav">
      <div class="container">
        <a class="has-text-white p-5"><font-awesome-icon icon="phone" /> 887-03-03 ext: 233</a>
        <a class="has-text-white p-5" href="mailto:mary1mj@hotmail.com"><font-awesome-icon icon="envelope" /> mary1mj@hotmail.com</a>
      </div>
    </div>
    <nav v-bind:class="isScrolling ? 'navbar is-black is-fixed-top' : 'navbar is-black'" role="navigation" aria-label="main navigation">
      <div class="navbar-brand ml-5">
        <a class="navbar-item" href="/">
          <img src="../assets/logo.png">
        </a>

        <a role="button" class="navbar-burger" @click="showNav = !showNav" :class="{ 'is-active': showNav }" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu" v-bind:class="{ 'is-active': showNav  }">

        <div class="navbar-end mr-5">
          <router-link to="/" class="navbar-item">Inicio</router-link>

          <router-link to="/avisos" class="navbar-item">Avisos</router-link>

          <router-link to="/anuncios-y-ayudiciones" class="navbar-item">Anuncios/Audiciones</router-link>

          <router-link to="/convocatorias" class="navbar-item">Convocatorias</router-link>
					<router-link to="/videos" class="navbar-item">Videos</router-link>

          <router-link to="/contacto" class="navbar-item">Contacto</router-link>

        </div>
      </div>
    </nav>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data(){
    return{
      showNav: false,
      isScrolling:false,
    }
  },
  methods:{
    handleScroll () {
      this.isScrolling = window.scrollY > 100 ? true: false;
    }
  },
  created :function(){
    window.addEventListener('scroll', this.handleScroll);
  }
}
</script>

<style>
.navbar.is-black-transparent {
    background-color: transparent;
    color: white;
}
.mini-nav{
  padding: 0.4rem;
}
.navbar-item img{
  max-height: 5rem!important;
  margin-left: 2rem;
}
</style>
