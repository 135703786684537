<template>
  <div>

    <header class="header-home">
      <div class="content-header">
        <h1 class="header-title">Bienvenidos</h1>
        <p class="header-text">Músicos y Artistas CROC Cancún</p>
        <p class="header-text">Horarios de Atención: <br>Lunes a Viernes  de 9am - 2pm</p>

      </div>
    </header>

		<section class="hero is-light">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h2 class="title is-1 mt-5" style="color:#000!important;"> Videos </h2>
          <div class="columns">
            <div class="column is-4" v-for="(v, index) in video" :key="index">
              <div class="video-container">
                  <iframe :src="v.video" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
              </div>
              <p>{{v.title}}</p>
            </div>
          </div>
          <router-link to="/videos" class="button is-danger-btn is-medium">Ver más</router-link>

        </div>
      </div>
    </section>

    <div class="container">


      <h1 class="title is-1 mt-5">Conoce nuestros beneficios</h1>

      <article class="message is-danger">
        <div class="message-body">
          Tramites directamente en las oficinas de músicos ubicada en la: <strong>Av Yaxchilán 23, 22, 77500 Cancún, Q.R. Tercer Piso</strong>
        </div>
      </article>

      <div class="columns">
        <div class="column">
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by3">
                <a @click="showEmail = true"><img src="../assets/afiliate.jpg" alt="Placeholder image"></a>
              </figure>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by3">
                <a @click="showEmail = true"><img src="../assets/permisos.jpg" alt="Placeholder image"></a>
              </figure>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by3">
                <a @click="showEmail = true"><img src="../assets/actas.jpg" alt="Placeholder image"></a>
              </figure>
            </div>
          </div>
        </div>

      </div>


      <div class="columns">
        <div class="column">
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by3">
                <a @click="showCredential = true"><img src="../assets/credenciales.jpg" alt="Placeholder image"></a>
              </figure>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by3">
                <a @click="showEmail = true"><img src="../assets/eventos.jpg" alt="Placeholder image"></a>
              </figure>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by3">
                <router-link to="/programas-sociales"><img src="../assets/beneficios.jpg" alt="Placeholder image"></router-link>
              </figure>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="modal is-active" v-show="showCredential">
      <div class="modal-background"></div>
      <div class="modal-content">
        <img src="../assets/banner.jpeg" alt="">
      </div>
      <button class="modal-close is-large" aria-label="close" @click="showCredential = false"></button>
    </div>

    <div class="modal is-active" v-show="showEmail">
      <div class="modal-background"></div>
      <div class="modal-content has-background-light">
        <h1 class="title mt-5 mb-5 has-text-centered">Contactanos!</h1>
        <form @submit.prevent="submit">
          <div class="notification is-primary" v-if="success">
            <button class="delete" @click="success=false"></button>
            Pronto nos pondremos en contacto contigo.
          </div>
          <div class="notification is-danger" v-if="errors">
            <button class="delete" @click="errors=false"></button>
            Error no podemos procesar tu petición en estos momentos intenta de nuevo.
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Nombre</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" v-model="name_contact" name="name_contact" placeholder="">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Email</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="email" v-model="email_contact" name="email_contact" placeholder="">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Mensaje</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <textarea class="textarea" v-model="message_contact" name="message_contact"></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Captcha</label>
            </div>
            <div class="field-body">
              <div class="field">
                <vue-recaptcha ref="recaptcha" sitekey="6LejPFgaAAAAADacmZ1E57eaNc911ywRxe9Lhnhq" @verify="onVerify" @expired="onExpired"></vue-recaptcha>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <button :disabled="status==='submitting'" class="button is-danger-btn">Enviar</button>

                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="showEmail = false"></button>
    </div>

  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios';
import {take} from 'lodash';

export default {
  name: 'Home',
  data(){
    return {
      showCredential:false,
      showEmail:false,
			video:[],
      status: "",
      email_contact:"",
      message_contact:"",
      name_contact:"",
      success:false,
      errors:false,
    }
  },
  components: {
    VueRecaptcha,
  },
  methods:{
    submit: function () {
      //this.$refs.invisibleRecaptcha.execute();
    },
    onVerify: function (recaptchaToken) {
      const self = this;
      this.$refs.recaptcha.reset();
      axios.post('https://api.plataformasocialmedia.pw/api/v1/contact?api_token=kxt11MMXZ8x5DUjdxzdDip14o5tuO9dePLujJRuZfXs3LdCc80', {
        type_web:'musicoscroc',
        email_contact: self.email_contact,
        name_contact:self.name_contact,
        message_contact:self.message_contact,
        recaptchaToken: recaptchaToken,
      }).then(() => {
        self.success = true;
        self.status = '';
      }).catch(() => {
       self.errors = true;
       self.status = '';
     });

    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
    },
		init(){
      axios.all([
        axios.post('https://api.plataformasocialmedia.pw/api/v1/video?page=1&api_token=kxt11MMXZ8x5DUjdxzdDip14o5tuO9dePLujJRuZfXs3LdCc80',{type_web:'musicos'})
      ])
      .then(axios.spread((videoData) => {
        this.video=take(videoData.data.data,3);
      })).catch((err) =>{

      });
    }
  },
  created :function(){
    this.init();
  }
}
</script>

<style>
.header-home {
  background:
    linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../assets/header.webp");
  background-size: cover;
  height: 100vh;
}

.content-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}
.header-title {
  text-transform: uppercase;
  margin: 0;
  font-size: 5rem;
  white-space: nowrap;
}
.header-text {
  margin: 0;
  font-size: 2rem;
}
</style>
