<template>
  <div>
    <section class="hero is-danger-bg">
      <div class="hero-body">
        <p class="title">
          Página no encontrada
        </p>
      </div>
    </section>
    <section class="section has-text-centered">
      <h1 class="title is-size-1">ERROR 404</h1>
      <h2 class="subtitle">Upps! Esta página no existe.</h2>
      <a href="/" class="button is-danger-btn">Inicio</a>

    </section>

  </div>
</template>

<script>

export default {
  name: 'Error404',
}
</script>


<style>

</style>
