<template>
  <div>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          Todos los Derechos Reservados &copy;<strong> Músicos y Artistas Cancún</strong> 2021. 
        </p>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'Footer',
}
</script>
