<template>
  <div>
    <section class="hero is-danger-bg">
      <div class="hero-body">
        <p class="title">
          Videos
        </p>
        <p class="subtitle">
          Mantente informado!
        </p>
      </div>
    </section>

    <div class="container mt-5 mb-5">

            <div class="columns mt-01" v-for="(videos, index) in chunkedVideos" :key="index">

              <div class="column is-6" v-for="(v, i) in videos" :key="i">
                <div class="video-container">
                  <iframe :src="v.video" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
                </div>
                <span class="is-size-5 mt-01">{{ v.title }}</span>
              </div>

            </div>
      <pagination v-if="links!=null" :links="links" :meta="meta" @paginate="init()"></pagination>

    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import axios from 'axios';
import {chunk} from 'lodash';

export default {
  name: 'Videos',
  data () {
    return {
      videos:[],
      links:[],
      meta:{'current_page':1},
    }
  },
  components: {
    Pagination,
  },
  computed: {
    chunkedVideos () {
      return chunk(this.videos,2)
    }
  },
  methods:{
    init: function(){
      var self = this;
      axios.post('https://api.plataformasocialmedia.pw/api/v1/video?page='+this.meta.current_page+'&api_token=kxt11MMXZ8x5DUjdxzdDip14o5tuO9dePLujJRuZfXs3LdCc80', {type_web:'musicos'})
      .then(function (response) {
        self.videos=response.data.data;
        self.links=response.data.links;
        self.meta=response.data.meta;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
  },
  created :function(){
    this.init();
  }
}
</script>
<style>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.frame-video{
  position: relative;
  width: 100%;
  display: block;
  height: 500px;
  padding-bottom: 56.25%;
}
</style>
